import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  DatePicker,
  Table,
  message,
  Upload,
  Button,
  InputNumber,
  Select,
  Form,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import dayjs from "dayjs";
import CURRENCIES from "../../../../util/word-currency.json";

const ExpenseReportTemplate = ({
  expenseReportAnswers,
  setExpenseReportAnswers,
}) => {
  const [tableData, setTableData] = useState([]);

  const handleAddExpenseRow = () => {
    setExpenseReportAnswers((prevSelectedReportAnswers) => {
      return {
        ...prevSelectedReportAnswers,
        answers: {
          ...prevSelectedReportAnswers.answers,
          expenseItems: [
            ...prevSelectedReportAnswers.answers.expenseItems,
            {
              id: prevSelectedReportAnswers.answers.expenseItems.length
                ? prevSelectedReportAnswers.answers.expenseItems.length + 1
                : 1,
              expense_date: "",
              description: "",
              expense_type: "",
              expense_evidence: "",
              amount: "",
            },
          ],
        },
      };
    });
  };

  const handleRemoveExpenseRow = (id) => {
    if (expenseReportAnswers?.answers.expenseItems.length <= 1) {
      message.error("You cannot remove the last row");
      return;
    }

    setExpenseReportAnswers((prevSelectedReportAnswers) => {
      const updatedItems =
        prevSelectedReportAnswers.answers.expenseItems.filter(
          (item) => item.id !== id
        );

      // Calculate the new total after removal
      const newTotal = updatedItems.reduce((acc, item) => {
        const amount = parseFloat(item.amount) || 0;
        return acc + amount;
      }, 0);

      return {
        ...prevSelectedReportAnswers,
        answers: {
          ...prevSelectedReportAnswers.answers,
          expenseItems: updatedItems,
          total_expenses: newTotal.toFixed(2),
        },
      };
    });
  };

  const handleChangeRowItem = useCallback(
    (id, key, value) => {
      setExpenseReportAnswers((prevSelectedReportAnswers) => {
        const updatedItems = prevSelectedReportAnswers.answers.expenseItems.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                [key]: value,
              };
            }
            return item;
          }
        );

        const total = updatedItems.reduce((acc, item) => {
          const amount = parseFloat(item.amount) || 0;
          return acc + amount;
        }, 0);

        return {
          ...prevSelectedReportAnswers,
          answers: {
            ...prevSelectedReportAnswers.answers,
            expenseItems: updatedItems,
            total_expenses: total.toFixed(2),
          },
        };
      });
    },
    [setExpenseReportAnswers]
  );

  useEffect(() => {
    const props = (itemID) => ({
      name: "file",
      beforeUpload(file) {
        return false;
      },
      onChange(info) {
        if (info.fileList.length > 1) {
          message.error(`You can only upload 1 file at a time!`);
          // Remove the extra file from the list
          info.fileList.pop();
        }
        handleChangeRowItem(
          itemID,
          "expense_evidence",
          info.fileList?.[0]?.originFileObj
        );
      },
    });

    if (expenseReportAnswers?.answers?.expenseItems) {
      const new_array = expenseReportAnswers?.answers?.expenseItems.map(
        (item, index) => {
          return {
            key: item.id,
            sn: item.id,
            date: (
              <Form.Item
                name={`expenseItems.${index}.expense_date`}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please select a date",
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  className="expense"
                  value={item.expense_date ? dayjs(item.expense_date) : null}
                  style={{
                    padding: "0.5rem 0.5rem",
                  }}
                  onChange={(date, dateString) => {
                    handleChangeRowItem(item.id, "expense_date", dateString);
                  }}
                />
              </Form.Item>
            ),
            description: (
              <Form.Item
                name={`expenseItems.${index}.description`}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please write a description",
                  },
                ]}
              >
                <Input
                  placeholder="Description"
                  size="large"
                  value={item.description}
                  className="expense"
                  onChange={(e) => {
                    handleChangeRowItem(item.id, "description", e.target.value);
                  }}
                />
              </Form.Item>
            ),
            expenseType: (
              <Form.Item
                name={`expenseItems.${index}.expense_type`}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please type the expense type",
                  },
                ]}
              >
                <Input
                  placeholder="Expense Type"
                  size="large"
                  value={item.expense_type}
                  className="expense"
                  onChange={(e) => {
                    handleChangeRowItem(
                      item.id,
                      "expense_type",
                      e.target.value
                    );
                  }}
                />
              </Form.Item>
            ),
            evidence: (
              <Form.Item
                name={`expenseItems.${index}.expense_evidence`}
                hasFeedback
                rules={[
                  {
                    required: false,
                    message: "Please upload evidence",
                  },
                ]}
              >
                <Upload {...props(item.id)}>
                  <Button icon={<UploadOutlined />} size="large">
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            ),
            amount: (
              <Form.Item
                name={`expenseItems.${index}.amount`}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please type the amount",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Amount"
                  size="large"
                  value={item.amount}
                  className="expense"
                  onChange={(value) => {
                    handleChangeRowItem(item.id, "amount", value);
                  }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Form.Item>
            ),
          };
        }
      );

      setTableData(new_array);
    }
  }, [handleChangeRowItem, expenseReportAnswers?.answers?.expenseItems]);

  // console.log("tableData", tableData);

  useEffect(() => {
    const totalExpenses = parseFloat(
      expenseReportAnswers?.answers?.total_expenses
    );
    const advanceCollected =
      parseFloat(expenseReportAnswers?.answers?.advance_collected) || 0;
    const balanceAmount = advanceCollected - totalExpenses;

    if (expenseReportAnswers?.answers?.balance_amount) {
      setExpenseReportAnswers((prevSelectedReportAnswers) => ({
        ...prevSelectedReportAnswers,
        answers: {
          ...prevSelectedReportAnswers.answers,
          balance_amount: balanceAmount ? balanceAmount.toFixed(2) : 0,
        },
      }));
    }
  }, [
    expenseReportAnswers?.answers?.total_expenses,
    expenseReportAnswers?.answers?.advance_collected,
  ]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Expense Type",
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
    },
    {
      title: "Evidence",
      dataIndex: "evidence",
      key: "evidence",
      align: "center",
    },
    {
      title: `Amount (${expenseReportAnswers?.answers?.currency?.symbol})`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <IoIosCloseCircleOutline
          onClick={() => handleRemoveExpenseRow(record.key)}
          opacity={
            expenseReportAnswers?.answers?.expenseItems.length === 1 ? 0.5 : 1
          }
          size={25}
        />
      ),
    },
  ];

  const onChange = (value, option) => {
    setExpenseReportAnswers((prevSelectedReportAnswers) => ({
      ...prevSelectedReportAnswers,
      answers: {
        ...prevSelectedReportAnswers.answers,
        currency: {
          ...prevSelectedReportAnswers.answers.currency,
          name: value,
          symbol: option?.symbol,
        },
      },
    }));
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="predefined__template__container">
      <div className="predefined__template__header">
        <div
          className="d-flex flex-column gap-2"
          style={{
            gridColumn: "1/3",
          }}
        >
          <label className="fw-bold fs-6">Request Subject:</label>
          <Form.Item
            name={`requestSubject`}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please type the request subject",
              },
            ]}
          >
            <Input
              placeholder="Request Subject"
              size="large"
              value={expenseReportAnswers?.answers?.requestSubject}
              onChange={(e) => {
                setExpenseReportAnswers((prevSelectedReportAnswers) => ({
                  ...prevSelectedReportAnswers,
                  answers: {
                    ...prevSelectedReportAnswers.answers,
                    requestSubject: e.target.value,
                  },
                }));
              }}
              style={{
                border: "2px solid black",
              }}
            />
          </Form.Item>
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fw-bold fs-6">From</label>
          <Form.Item
            name={`expensePeriod.start_date`}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please choose the start date",
              },
            ]}
          >
            <DatePicker
              size="large"
              value={
                expenseReportAnswers?.answers?.expensePeriod?.start_date
                  ? dayjs(
                      expenseReportAnswers?.answers?.expensePeriod?.start_date
                    )
                  : null
              }
              onChange={(date, dateString) => {
                setExpenseReportAnswers((prevSelectedReportAnswers) => ({
                  ...prevSelectedReportAnswers,
                  answers: {
                    ...prevSelectedReportAnswers.answers,
                    expensePeriod: {
                      ...prevSelectedReportAnswers.answers.expensePeriod,
                      start_date: dateString,
                    },
                  },
                }));
              }}
              style={{
                padding: "0.6rem 0.5rem",
                border: "2px solid black",
                width: "100%",
              }}
            />
          </Form.Item>
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fw-bold fs-6">To</label>
          <Form.Item
            name={`expensePeriod.end_date`}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please choose the end date",
              },
            ]}
          >
            <DatePicker
              size="large"
              value={
                expenseReportAnswers?.answers?.expensePeriod?.end_date
                  ? dayjs(
                      expenseReportAnswers?.answers?.expensePeriod?.end_date
                    )
                  : null
              }
              onChange={(date, dateString) => {
                setExpenseReportAnswers((prevSelectedReportAnswers) => ({
                  ...prevSelectedReportAnswers,
                  answers: {
                    ...prevSelectedReportAnswers.answers,
                    expensePeriod: {
                      ...prevSelectedReportAnswers.answers.expensePeriod,
                      end_date: dateString,
                    },
                  },
                }));
              }}
              style={{
                padding: "0.6rem 0.5rem",
                border: "2px solid black",
                width: "100%",
              }}
            />
          </Form.Item>
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fw-bold fs-6">Select Currency:</label>
          <Select
            showSearch
            size="large"
            placeholder="Select currency"
            optionFilterProp="children"
            value={expenseReportAnswers?.answers?.currency.name}
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            style={{
              border: "2px solid black",
              borderRadius: "10px",
            }}
            options={CURRENCIES.map((currency) => ({
              value: currency.code,
              label: `${currency.symbol} ${currency.name}`,
              symbol: currency.symbol,
            }))}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-4 ">
        <Button
          size="large"
          type="primary"
          onClick={() => handleAddExpenseRow()}
          className="align-self-end"
          style={{
            backgroundColor: "var(--clr-primary)",
          }}
        >
          Add Expense Item
        </Button>
        <Table
          columns={columns}
          dataSource={tableData}
          // scroll={{ x: 3200 }}
          bordered
          pagination={false}
          style={{
            width: "100%",
          }}
        />

        <div className="d-flex flex-column gap-4 align-items-end">
          <div className="d-flex gap-2 align-items-center">
            <label className="fw-bold fs-6">
              Total Expenses ({expenseReportAnswers?.answers?.currency?.symbol}
              ):
            </label>
            <InputNumber
              placeholder="0.00"
              size="large"
              className="expense"
              prefix={expenseReportAnswers?.answers?.currency?.symbol}
              value={parseFloat(expenseReportAnswers?.answers?.total_expenses)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\s?|(,*)/g, "")}
              style={{
                width: "13rem",
              }}
              disabled
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <label className="fw-bold fs-6">
              Advance Collected (
              {expenseReportAnswers?.answers?.currency?.symbol}):
            </label>
            <InputNumber
              placeholder="0.00"
              size="large"
              prefix={expenseReportAnswers?.answers?.currency?.symbol}
              value={expenseReportAnswers?.answers?.advance_collected}
              className="expense"
              onChange={(value) => {
                const totalExpenses = parseFloat(
                  expenseReportAnswers?.answers?.total_expenses
                );
                const advanceCollected = parseFloat(value) || 0;
                const balanceAmount = advanceCollected - totalExpenses;

                setExpenseReportAnswers((prevSelectedReportAnswers) => ({
                  ...prevSelectedReportAnswers,
                  answers: {
                    ...prevSelectedReportAnswers.answers,
                    advance_collected: value,
                    balance_amount: parseFloat(balanceAmount).toFixed(2),
                  },
                }));
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "13rem",
              }}
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <label className="fw-bold fs-6">
              Balance ({expenseReportAnswers?.answers?.currency?.symbol}):
            </label>
            <InputNumber
              placeholder="0.00"
              size="large"
              className="expense"
              prefix={expenseReportAnswers?.answers?.currency?.symbol}
              value={expenseReportAnswers?.answers?.balance_amount}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\s?|(,*)/g, "")}
              style={{
                width: "13rem",
              }}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseReportTemplate;
