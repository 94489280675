import styles from "../../styles/hrms/Sidebar.module.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { canAccess } from "../../util/helpers";
import { FaHome } from "react-icons/fa";

function PermissionsDashboardSidebar({ handleToggle }) {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>
        <ul className="performance__navlinks ">
          {canAccess(
            "view-create-group-menu-human-resource-information-system-preferences"
          ) && (
            <li>
              <NavLink
                to={""}
                end
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span className="sidebar_text">Overview</span>
              </NavLink>
            </li>
          )}
          {canAccess(
            "view-create-group-menu-human-resource-information-system-preferences"
          ) && (
            <li>
              <NavLink
                to={"create-group"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span className="sidebar_text">Create User Group</span>
              </NavLink>
            </li>
          )}

          {canAccess(
            "view-view-groups-menu-human-resource-information-system-preferences"
          ) && (
            <li>
              <NavLink
                to={"view-groups"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span className="sidebar_text">View User Groups</span>
              </NavLink>
            </li>
          )}

          {canAccess(
            "view-assign-user-group-menu-human-resource-information-system-preferences"
          ) && (
            <li>
              <NavLink
                to={"assign-group"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span className="sidebar_text">Assign User(s) Group</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default PermissionsDashboardSidebar;
