import React, { useState } from "react";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PayScaleDropdown from "./dropdowns/PayScaleDropdown";
import WageItemsDropdown from "./dropdowns/WageItemsDropdown";
import PayStructureDropdown from "./dropdowns/PayStructureDropdown";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const parentPath = location.pathname.split("/")[3];

  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>
        <ul className="performance__navlinks ">
          <li>
            <NavLink
              to={""}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Overview</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"list"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Benefit</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"pay-grades"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Pay Grades</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"pay-structure"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Pay Structure</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"pay-roll"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Payroll</span>
            </NavLink>
          </li>
          {/* 
          <li>
            <NavLink
              to={"analytics"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Report & Analytics</span>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to={"settings"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Settings</span>
            </NavLink>
          </li> */}
        </ul>
      </nav>

      <div className="performance__nav__subnav">
        {parentPath === "pay-grades" ? (
          <PayScaleDropdown />
        ) : parentPath === "pay-structure" ? (
          <PayStructureDropdown />
        ) : parentPath === "pay-roll" ? (
          <WageItemsDropdown />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
