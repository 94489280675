import React, { useState } from "react";
import styles from "../../styles/HomeLayout.module.css";
import {
  MdOutlineDashboard,
  MdOutlinePermContactCalendar,
  MdOutlineDocumentScanner,
  MdViewModule,
  MdLockOutline,
} from "react-icons/md";
import { FaRegUserCircle, FaHandshake } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { RiHandCoinFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { dehashData } from "../../util/hash";
import { company_details } from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
import {
  active_modules,
  detached_modules,
} from "../../util/selectors/userSelectors";
import {
  useGetActiveModules,
  useGetDetachedModules,
} from "../../util/usershookActions";

const HomeLayoutSidebar = () => {
  const {
    REACT_APP_AUTH_DETAILS: AUTH_DETAILS,
    REACT_APP_PURCHASES_URL: purchases_url,
  } = process.env;

  const { logo } = dehashData(AUTH_DETAILS) ?? { logo: "" };
  const companyDetails = useShallowEqualSelector(company_details);
  const [enabled_det_mod, setEnabledDeTMod] = useState(true);
  const [enabled_act_mod, setEnabledActMod] = useState(true);

  const activeModules = useShallowEqualSelector(active_modules);
  const detachedModules = useShallowEqualSelector(detached_modules);

  useGetActiveModules(enabled_act_mod, setEnabledActMod);
  useGetDetachedModules(enabled_det_mod, setEnabledDeTMod);

  const isActiveClass = styles.selected;
  const isDisabled = true;

  return (
    <div className={styles.container}>
      <div className={styles.companyInfo}>
        <div className={styles.companyLogo}>
          <img src={logo || "/assets/office-building.png"} alt="user-company" />
        </div>
        <h5 className="">{companyDetails?.business.company_name}</h5>
      </div>
      <nav className={styles.navLinkWrapper}>
        <div className={styles.navLinkGroup}>
          <NavLink
            to={"/dashboard"}
            end
            className={({ isActive }) => (isActive ? isActiveClass : "")}
            style={({ isActive }) =>
              isActive
                ? { color: "var(--clr-primary)", backgroundColor: "white" }
                : null
            }
          >
            <MdOutlineDashboard size={30} />
            <span className={styles.linktext}>Dashboard</span>
          </NavLink>

          <NavLink
            to={"/profile"}
            className={({ isActive }) => (isActive ? isActiveClass : "")}
            style={({ isActive }) =>
              isActive
                ? { color: "var(--clr-primary)", backgroundColor: "white" }
                : null
            }
          >
            <FaRegUserCircle size={30} />
            <span className={styles.linktext}>Profile</span>
          </NavLink>

          <NavLink
            to={"subscriptions"}
            className={({ isActive }) => (isActive ? isActiveClass : "")}
            style={({ isActive }) =>
              isActive
                ? { color: "var(--clr-primary)", backgroundColor: "white" }
                : null
            }
          >
            <MdViewModule size={30} />
            <span className={styles.linktext}>Subscriptions</span>
          </NavLink>

          <NavLink
            to={"/modules/permissions"}
            className={({ isActive }) => (isActive ? isActiveClass : "")}
            style={({ isActive }) =>
              isActive
                ? { color: "var(--clr-primary)", backgroundColor: "white" }
                : null
            }
          >
            <MdLockOutline size={30} />
            <span className={styles.linktext}>Permissions</span>
          </NavLink>
        </div>

        <div className={styles.navLinkGroup}>
          <h6 className={styles.navLinkGroupHeader}>Subscribed Modules</h6>
          {activeModules
            .sort((a, b) => (a.flag != null && b.flag == null ? -1 : 1))
            ?.map(({ module_on_subscription }) => (
              <NavLink
                to={`/modules${module_on_subscription?.path}`}
                key={module_on_subscription?.id}
                id={module_on_subscription.id}
                className={({ isActive }) => (isActive ? isActiveClass : "")}
                style={({ isActive }) =>
                  isActive
                    ? { color: "var(--clr-primary)", backgroundColor: "white" }
                    : null
                }
              >
                {module_on_subscription?.short_name === "Hris" ? (
                  <FaHandshake size={30} />
                ) : module_on_subscription?.short_name === "Leave" ? (
                  <MdOutlinePermContactCalendar size={30} />
                ) : module_on_subscription?.short_name === "Performance" ? (
                  <RiTeamLine size={30} />
                ) : module_on_subscription?.short_name === "Compensation" ? (
                  <RiHandCoinFill size={30} />
                ) : (
                  <RiTeamLine size={30} />
                )}
                <span className={styles.linktext}>
                  {module_on_subscription.name}
                </span>
              </NavLink>
            ))}
        </div>

        <div className={styles.navLinkGroup}>
          <h6 className={styles.navLinkGroupHeader}>Available Modules</h6>

          {detachedModules?.map(({ id, name, image, short_name }) => (
            <a
              key={id}
              href={
                isDisabled
                  ? undefined
                  : `${purchases_url}${companyDetails.business.id}&short_name=${short_name}`
              }
              className={styles.disabled}
              target={isDisabled ? undefined : "_blank"}
              rel={isDisabled ? undefined : "noreferrer"}
              onClick={(event) => {
                if (isDisabled) {
                  event.preventDefault(); // Prevent navigation
                }
              }}
            >
              <MdOutlineDocumentScanner size={25} />
              <span style={{ fontSize: "1.1rem" }}>{name}</span>
            </a>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default HomeLayoutSidebar;
