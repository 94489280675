import { useState, useEffect } from "react";
import HocHeader from "../../ui/HocHeader";
import { Steps, Avatar, Button } from "antd";
import { useDispatch } from "react-redux";

import { useGetTrackMyRequests } from "../../../util/usershookActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";

import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
import { nudgeRequestApprover } from "../../../redux/users/userActions";

dayjs.extend(customParseFormat);

const TrackMyRequest = () => {
  const [enabled, setEnabled] = useState(true);
  const [requests, setRequests] = useState([]);
  const [nudgeLoading, setNudgeLoading] = useState({});

  const { data, refetch, isLoading } = useGetTrackMyRequests(
    enabled,
    setEnabled
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const new_array = data?.payload?.map((request, index) => {
        return {
          key: request.submission_id,
          requestType: request.template_name,
          dateOfRequest: dayjs(
            request.date_of_request,
            "YYYY/MM/DD hh:mma"
          ).format("DD MMM, YYYY, hh:mma"),
          steps: Object.values(request.approvalData).map((approval, index) => {
            return {
              title: approval?.approver,
              status: approval?.status,
              avatarUrl: approval?.logo,
              approverID: approval?.approval_id,
              nudgedAt: approval?.nudge_sent_at,
            };
          }),
        };
      });
      setRequests(new_array);
    }
  }, [data]);

  const handleNudgeApprover = (approverID) => {
    setNudgeLoading((prevState) => ({ ...prevState, [approverID]: true }));

    nudgeRequestApprover(dispatch, approverID).then(() => {
      setNudgeLoading((prevState) => ({ ...prevState, [approverID]: false }));
      refetch();
    });
  };

  return (
    <>
      <div>
        <HocHeader
          module_title="Request Management"
          module_class="performance-header"
          module_name={"Track My Request"}
          module_description="Monitor the status of your requests in real time, ensuring you stay updated every step of the way."
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "2rem",
              }}
            >
              <div className="">
                <h3 className="h4">Track My Requests</h3>
                <p>
                  Monitor your requests to know the status and process of your
                  request
                </p>
              </div>
            </div>

            <div className="d-flex flex-column gap-2 pb-5">
              {isLoading ? (
                <SkeletonUI number={5} />
              ) : requests.length > 0 && requests ? (
                requests?.map((request, index) => (
                  <div key={index} className="track_request_card">
                    <div className="d-flex flex-column align-items-between">
                      <div className="d-flex flex-column gap-2">
                        <small className="">Request Type:</small>
                        <p className="fs-6 fw-bold">{request.requestType}</p>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <small className="">Date of Request:</small>
                        <p className="fs-6 fw-bold">{request.dateOfRequest}</p>
                      </div>
                    </div>

                    <div className="request__steps">
                      <Steps
                        current={request.steps.findIndex(
                          (step) => step.status === "pending"
                        )}
                        labelPlacement="vertical"
                        size="large"
                        className="track__request__steps"
                      >
                        {request.steps.map((step, index) => {
                          // Check if this step is the first pending step
                          const isFirstPending =
                            request.steps.findIndex(
                              (step) => step.status === "pending"
                            ) === index;

                          return (
                            <Steps.Step
                              key={index}
                              title={step.title}
                              status={step.status}
                              description={
                                <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
                                  <p>{step.status?.toUpperCase()}</p>
                                  <Avatar src={step.avatarUrl} size={40} />
                                  {isFirstPending && (
                                    <Button
                                      type="primary"
                                      style={{
                                        backgroundColor: "var(--clr-primary)",
                                        width: "fit-content",
                                        marginTop: "0.5rem",
                                      }}
                                      onClick={() =>
                                        handleNudgeApprover(step.approverID)
                                      }
                                      disabled={step.nudgedAt}
                                      loading={nudgeLoading[step.approverID]}
                                    >
                                      Nudge
                                    </Button>
                                  )}
                                </div>
                              }
                            />
                          );
                        })}
                      </Steps>
                    </div>
                  </div>
                ))
              ) : (
                <NoContent
                  width={"400px"}
                  message={"You have no active requests"}
                  illustration={"/assets/selected.png"}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TrackMyRequest;
