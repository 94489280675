import React, { useState, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import styles from "../../../styles/CompanyDetails.module.css";
import countries from "../../../util/countries.json";
import { settings, user_errors } from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../util/hooks";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import { updateClientComDetails } from "../../../redux/users/userActions";
import { useGetSystemSettings } from "../../../util/usershookActions";

function CompanyDetails() {
  const dispatch = useDispatch();
  const [enabled_settings, setEnabledSettings] = useState(true);
  useGetSystemSettings(enabled_settings, setEnabledSettings);
  const errors = useShallowEqualSelector(user_errors);
  const all_settings = useShallowEqualSelector(settings);
  const initCompanyDetails = {
    address: all_settings?.company_settings?.address?.value || "",
    city: all_settings?.company_settings?.city?.value || "",
    region: all_settings?.company_settings?.region?.value || "",
    country: all_settings?.company_settings?.country?.value || "",
    rc_number: all_settings?.company_settings?.rc_number?.value || "",
    phone_number: all_settings?.company_settings?.phone_number?.value || "",
    mobile_number: all_settings?.company_settings?.mobile_number?.value || "",
  };

  console.log({ all_settings });

  const [values, setValues] = useState(initCompanyDetails);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalView, setShowModalView] = useState(false);

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };
  const handleupdate = () => {
    setLoading(true);
    updateClientComDetails(dispatch).then((res) => {
      setLoading(false);
      if (res?.status === "success") setEnabledSettings(true);
    });
    // console.log(values);
  };

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };
  const handleModalView = (e) => {
    e.preventDefault();
    setShowModalView(!showModalView);
  };

  useEffect(() => {
    setValues({
      office_address:
        all_settings?.company_settings?.office_address?.value || "",
      city: all_settings?.company_settings?.city?.value || "",
      region: all_settings?.company_settings?.region?.value || "",
      country: all_settings?.company_settings?.country?.value || "",
      rc_number: all_settings?.company_settings?.rc_number?.value || "",
      phone_number: all_settings?.company_settings?.phone_number?.value || "",
      mobile_number: all_settings?.company_settings?.mobile_number?.value || "",
    });
  }, [all_settings]);

  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Company Details</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <small>View and edit your company details </small>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="d-flex gap-1">
                  <Button
                    type="text"
                    onClick={handleModalView}
                    // loading={loadingre}
                  >
                    View
                  </Button>
                  <Button type="text" onClick={handleModal}>
                    Edit
                  </Button>
                </div>
              </div>

              <Modal
                open={showModal}
                footer={null}
                centered
                onCancel={handleModal}
                // width={700}
              >
                <div className="row">
                  <div className={`${styles.content} col-lg-12`}>
                    <h3>Company Details</h3>
                    <form className={`${styles.form}  adjust_style`}>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="office_address">Address</label>
                          <span>{errors.office_address}</span>
                        </div>
                        <input
                          type="text"
                          name="office_address"
                          placeholder="12, Abc rd"
                          value={values.office_address}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="city">City</label>
                          <span>{errors.city}</span>
                        </div>
                        <input
                          type="text"
                          name="city"
                          placeholder="Gbagada"
                          value={values.city}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="region">State/Province/Region</label>
                          <span>{errors.region}</span>
                        </div>
                        <input
                          type="text"
                          name="region"
                          placeholder="Lagos"
                          value={values.region}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="country">Country</label>
                          <span>{errors.country}</span>
                        </div>
                        <select
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                          <option value="">--</option>
                          {countries
                            .sort((a, b) => {
                              if (a.name < b.name) {
                                return -1;
                              }
                              if (a.name > b.name) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((country) => (
                              <option key={country.id} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="rc_number">RC Number</label>
                          <span>{errors.rc_number}</span>
                        </div>

                        <input
                          type="text"
                          name="rc_number"
                          placeholder="NG-12KD9HNS"
                          value={values.rc_number}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="phone_number">Phone Number</label>
                          <span>{errors.phone_number}</span>
                        </div>

                        <input
                          type="number"
                          name="phone_number"
                          placeholder="+234 901 234 5678"
                          value={values.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="mobile_number">Mobile Number</label>
                        </div>
                        <input
                          type="number"
                          name="mobile_number"
                          placeholder="+1 234 5678"
                          value={values.mobile_number}
                          onChange={handleChange}
                        />
                      </div>
                    </form>
                    <div className="d-flex justify-content-end mt-4">
                      <div className="">
                        <Button
                          className="myBtn mb-2"
                          loading={loading}
                          onClick={handleupdate}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                open={showModalView}
                footer={null}
                centered
                onCancel={handleModalView}
                // width={700}
              >
                <div className="row">
                  <div className={`${styles.content} col-lg-12`}>
                    <h3>Company Details</h3>
                    <form className={`${styles.form}  adjust_style`}>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="office_address">Address</label>
                        </div>
                        {values.office_address || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="city">City</label>
                          <span>{errors.city}</span>
                        </div>
                        {values.city || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="region">State/Province/Region</label>
                          <span>{errors.region}</span>
                        </div>
                        {values.region || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="country">Country</label>
                          <span>{errors.country}</span>
                        </div>
                        {values.country || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="rc_number">RC Number</label>
                          <span>{errors.rc_number}</span>
                        </div>
                        {values.rc_number || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="phone_number">Phone Number</label>
                          <span>{errors.phone_number}</span>
                        </div>
                        {values.phone_number || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <div className={styles.top}>
                          <label htmlFor="mobile_number">Mobile Number</label>
                        </div>
                        {values.mobile_number || "N/A"}
                      </div>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
