import React from "react";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CreateRequestDropdown from "./dropdowns/CreateRequestDropdown";
import MakeRequestDropdown from "./dropdowns/MakeRequestDropdown";
import { FaHome } from "react-icons/fa";

const Sidebar = () => {
  const location = useLocation();

  const parentPath = location.pathname.split("/")[3];

  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>

        <ul className="performance__navlinks ">
          <li>
            <NavLink
              to={""}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Overview</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"new-request-template"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">New Request Template</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"make-request"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Make a Request</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"track-request"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Track my Request</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"approve-requests"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Approve Requests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"history"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">History</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="performance__nav__subnav">
        {parentPath === "new-request-template" ? (
          <CreateRequestDropdown />
        ) : parentPath === "make-request" ? (
          <MakeRequestDropdown />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
