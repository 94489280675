import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";

const NewRequestTemplate = () => {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Request Management"
          module_class="performance-header"
          module_name={
            childPath === "edit-request"
              ? "Edit Request"
              : "Create New Template"
          }
          module_description="Create and customize new templates effortlessly to meet your specific needs, streamlining your workflow and enhancing productivity."
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default NewRequestTemplate;
