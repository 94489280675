import React, { useState, useRef, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Button } from "antd";
import { logoutClient } from "../redux/users/userActions";
import { useDispatch } from "react-redux";
import { AiFillBell } from "react-icons/ai";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

function IdleContainer() {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const to_logout = useRef(null);

  // Get timeout value from .env (convert minutes to milliseconds)
  const timeoutDuration =
    (parseInt(process.env.REACT_APP_TIMEOUT, 10) || 10) * 60 * 1000;

  console.log({ timeoutDuration });

  const cancelCounter = () => {
    clearTimeout(to_logout.current);
    setShowNotification(false);
  };

  const onIdle = () => {
    setShowNotification(true);

    to_logout.current = setTimeout(() => {
      logoutClient(dispatch);
      setShowNotification(false);
    }, 59000);
  };

  const onActive = () => {
    clearTimeout(to_logout.current);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: timeoutDuration, // Use the timeout from .env
    promptBeforeIdle: 0,
  });

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setCountdown(60);
    }

    return () => clearTimeout(timer);
  }, [countdown, showNotification]);

  return (
    showNotification && (
      <div className="idle">
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-50">
            <h5>Inactivity</h5>
            <p style={{ lineHeight: 0.9, fontSize: 10 }}>
              You seem to have been inactive for a long time
            </p>
          </div>
          <AiFillBell className="h1" style={{ color: "var(--clr-primary)" }} />
        </div>
        <div
          className="d-flex flex-column gap-2 justify-content-center align-items-center mx-auto my-5"
          style={{ width: 200, height: 200 }}
        >
          <p>Logging out in...</p>
          <CircularProgressbar
            value={countdown}
            maxValue={60}
            text={countdown}
            strokeWidth={15}
            styles={buildStyles({
              pathColor: `${countdown > 20 ? "var(--clr-primary)" : "red"}`,
              textColor: `${countdown > 20 ? "var(--clr-primary)" : "red"}`,
            })}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button className="myBtn " onClick={cancelCounter}>
            Stay Active
          </Button>
        </div>
      </div>
    )
  );
}

export default IdleContainer;
